<template>
  <div :class="$style.CalculatorCourse">
    <RadialProgress
      :fps="1"
      :diameter="24"
      :stroke-width="1"
      :total-steps="totalSteps"
      :inner-stroke-width="1"
      :completed-steps="step"
    >
      <span :class="$style.steps">{{ step }}</span>
    </RadialProgress>
    <div v-if="hasTitle" :class="$style.title">{{ t("Курс") }}</div>
    <div :class="$style.course">
      {{ courseString }}
    </div>
  </div>
</template>

<script setup lang="ts">
import useCourse from "~/composables/calculator/useCourse";

const { step, courseString, totalSteps } = useCourse();

defineProps({
  hasTitle: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n({
  useScope: "local",
});
</script>

<i18n lang="json">
{
  "ru": {
    "Курс": "Курс"
  },
  "en": {
    "Курс": "Course"
  }
}
</i18n>

<style lang="scss" module>
.CalculatorCourse {
  display: flex;
  align-items: center;
  line-height: 1;
}

.steps {
  font-size: 14px;
  font-weight: 450;
  color: var(--text-color-8);
}

.title {
  margin-left: 8px;
  font-size: 16px;
  color: var(--text-color-4);

  @include respond-to(xs) {
    font-size: 12px;
  }
}

.course {
  margin-left: auto;
  font-size: 16px;
  color: var(--text-color-8);
  text-transform: uppercase;

  @include respond-to(xs) {
    font-size: 12px;
  }
}
</style>
