import { storeToRefs } from "pinia";
import type { ComputedRef, Ref } from "vue";
import { calculatorApi } from "~/utils/api/calculator";

interface UseCourseResult {
  step: Ref<number>;
  courseString: ComputedRef<string>;
  totalSteps: number;
}

export default function useCourse(steps?: number): UseCourseResult {
  const store = useCalculatorStore();
  const { pairData, course } = storeToRefs(store);
  const { setCourse } = store;
  const totalSteps = steps ?? 30;
  const step = ref(totalSteps);

  const courseString = computed(() => {
    if (!pairData?.value?.from || !pairData.value?.to) return "";
    const fromCurrency = pairData.value?.from?.currency;
    const fromRound = pairData.value?.from?.roundCalculator;
    const toCurrency = pairData.value?.to?.currency;
    const toRound = pairData.value?.to?.roundCalculator;
    if (!fromCurrency || !toCurrency || !course.value) return "";
    if (course.value >= 1) {
      return `1 ${fromCurrency} = ${roundNumber(
        course.value,
        toRound,
      )} ${toCurrency}`;
    }
    return `${roundNumber(
      1 / course.value,
      fromRound,
    )} ${fromCurrency} = 1 ${toCurrency}`;
  });

  const getCourse = async () => {
    const from = store.fromCurrencyId;
    const to = store.toCurrencyId;
    if (!from || !to) {
      return;
    }
    const { course } = await calculatorApi.getCourse({ from, to });
    setCourse(course);
  };

  useIntervalFn(async () => {
    step.value -= 1;
    if (step.value <= 0) {
      await getCourse();
      step.value = totalSteps;
    }
  }, 1000);

  return {
    totalSteps,
    step,
    courseString,
  };
}
